<template>
  <div class="pc_page_container_all">
    <NewsPage :items="items" />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import NewsPage from "@/components/PC/NewsPage.vue";
import { kPageContentInfo } from "@/config/base";

@Options({
  components: {
    NewsPage,
  },
  data() {
    return {
      items: kPageContentInfo.base,
    };
  },
})
export default class BasePage extends Vue {}
</script>

<style>
@import url("../../../less/pc/page.less");
</style>